@font-face {
  font-family: 'Avenir';
  src: url('../../../assets/fonts/AvenirNextCondensed-Regular-08.ttf');
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: #f6f9fc;
  padding: 1rem;
  border: 1px solid #ccc;
  height: 50vh;
  overflow-y: auto;
}

.toolbar-class {
  border: 1px solid #ccc;
}
